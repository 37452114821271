body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-family: "Oxygen", sans-serif;
  color: #222;
}

p {
  font-size: 1rem;
  font-family: "Mulish", sans-serif;
  margin: 0;
  color: #222;
}

.App {
  scroll-snap-type: y mandatory;
}

@media only screen and (min-width: 576px) {
  p {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  p {
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 992px) {
  p {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1200px) {
  p {
    font-size: 1.375rem;
  }
}

.svg-icon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: white;
  margin: 10px;
  transition: all 0.25s ease-in-out;
}

.svg-icon:hover {
  background: rgba(230, 230, 230, 1);
  padding: 10px;
  transform: translateY(-4px);
}

/* Animated Background css */

#home-anim {
  width: 2400px;
  min-height: 100vh;
}

#home {
  clip-path: url(#cache);
}

#red {
  fill: none;
  opacity: 0.15;
  stroke: #ce1b5f;
  stroke-width: 12;
  stroke-miterlimit: 10;
  animation: show 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

#blue {
  fill: none;
  opacity: 0.15;
  stroke: #06a1c4;
  stroke-width: 12;
  stroke-miterlimit: 10;
  animation: show 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

#light-blue {
  fill: none;
  opacity: 0.15;
  stroke: #06a1c4;
  stroke-width: 6;
  stroke-miterlimit: 10;
  stroke-dasharray: 200;
  stroke-dashoffset: 800;
  animation: draw 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0.15;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.15;
  }
}
